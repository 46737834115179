import { Injectable } from '@angular/core';
import { Storager } from './storager';

@Injectable({
  providedIn: 'root'
})
export class SessionStoragerService implements Storager {

  public constructor() {
  }

  public setElement(key: string, obj: any, encoded?: boolean) {
    var jsonObj = JSON.stringify(obj);
    if (encoded) {
      jsonObj = btoa(jsonObj);
    }
    sessionStorage.setItem(key, jsonObj);
  }

  public getElement(key: string, encoded?: boolean): any {
    var element = sessionStorage.getItem(key);
    if (!!element) {
      if (encoded) {
        element = atob(element);
      }
      return JSON.parse(element);
    }
    return;
  }

  public removeElement(key: string) {
    sessionStorage.removeItem(key);
  }

  public clearAll() {
    sessionStorage.clear();
  }

  public hasElement(key: string): boolean {
    return !!(sessionStorage.getItem(key));
  }

}
