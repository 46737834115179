import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../_service';
import { Constants } from '../Constants';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        if (this.auth.isExpiredToken()) {
            return this.auth.refreshToken();
        }

        if (this.auth.isLoggedIn) {
            // logged in so return true
            return true;
        }

        this.auth.SignOut();
        return false;
    }
}
