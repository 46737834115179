//enum
export * from './enum/Type';
export * from './enum/lang';
export * from './enum/StatoPlesso';
export * from './enum/StatoClasse';
export * from './enum/TipologiaReport';
//interfaced
export * from './interface/User';
export * from './interface/Authority';
//class
export * from './Classe';
export * from './Scuola';
export * from './Istituto';
export * from './Studente';
export * from './Plesso';
export * from './DettaglioScuole';
export * from './DettaglioClasse';
export * from './DashboardGenerale';
export * from './Comune';
export * from './DettaglioScuoleOrder';
export * from './DettaglioClassiOrder';
