import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard, Constants } from './_utility';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '', component: LayoutComponent, children: [
      { path: Constants.Routing.DASHBOARD.path, loadChildren: () => import('./layout/dashboard/dashboard.module').then(mod => mod.DashboardModule), canActivate: [AuthGuard] },
      { path: Constants.Routing.SCUOLE.path, loadChildren: () => import('./layout/scuole/scuole.module').then(mod => mod.ScuoleModule), canActivate: [AuthGuard] },
      { path: Constants.Routing.CLASSI.path, loadChildren: () => import('./layout/classi/classi.module').then(mod => mod.ClassiModule), canActivate: [AuthGuard] },
    ]
  },
  { path: Constants.Routing.LOGIN.path, loadChildren: () => import('./external-page/login/login.module').then(mod => mod.LoginModule) },
  { path: Constants.Routing.NOT_FOUND.path, loadChildren: () => import('./external-page/not-found/not-found.module').then(mod => mod.NotFoundModule) },
  { path: '**', redirectTo: '/not_found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
