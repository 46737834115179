<div class="loading-overlay" *ngIf="loading">
  <div class="wrap-spinner">
    <div class="lds">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
<router-outlet></router-outlet>