import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DettaglioScuola, Istituto } from '../_model';
import { Comune } from '../_model/Comune';
import { DettaglioScuolaOrder } from '../_model/DettaglioScuoleOrder';

@Injectable({
  providedIn: 'root',
})
export class ScuoleService {

  private host = environment.host + environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getDettaglioScuole(regione: string, comuneId: string, istitutoId: string, page: number, size: number, orderBy: string, orderType: string): Observable<DettaglioScuolaOrder> {
    let url = `${this.host}/dashboard/scuole`
    let queryParams = {
      size: size,
      page: page,
      orderBy: orderBy,
      orderType: orderType,
      comuneId: comuneId,
      istitutoId: istitutoId ? istitutoId : '',
      regione: regione
    }
    return this.http.get<DettaglioScuolaOrder>(url, { params: queryParams })
  }

  public getComuni(nome: string): Observable<Comune[]> {
    let url = `${this.host}/public/comuni`
    let queryParams = {
      nome: nome
    }
    return this.http.get<Comune[]>(url, { params: queryParams })
  }

  public getComuniPerRegione(nome: string): Observable<Comune[]> {
    let url = `${this.host}/public/regioni/${nome}/comuni`
    return this.http.get<Comune[]>(url)
  }


  public getIstitutiPerComune(id: string): Observable<Istituto[]> {
    let url = `${this.host}/public/comuni/${id}/istituti`
    return this.http.get<Istituto[]>(url)
  }

  getDettaglioScuoleReport(regione?: string, comuneId?: string, istitutoId?: string, orderBy?: string, orderType?: string) {
    let url = `${this.host}/export/scuole`;
    let queryParams: any = {}
    if (regione) {
      queryParams.regione = regione;
    }
    if (comuneId) {
      queryParams.comuneId = comuneId;
    }
    if (istitutoId) {
      queryParams.istitutoId = istitutoId;
    }
    if (orderBy) {
      queryParams.orderBy = orderBy;
    }
    if (orderType) {
      queryParams.orderType = orderType;
    }
    return this.http.get(url, { params: queryParams, responseType: 'text' });
  }

  public getLiberatoria(liberatoriaId: string) {
    let url = `${this.host}/dashboard/scuole/liberatorie/${liberatoriaId}`
    return this.http.get(url, {responseType: 'blob' })
  }

}
