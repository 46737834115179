import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event } from '@angular/router';
import { LocaleService } from './_utility';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'FUtuRI Admin';

  // Sets initial value to true to show loading spinner on first load
  loading = true;

  constructor(private router: Router, private locale: LocaleService) {
    this.locale.init();
    // Router event per loading page
    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
  }

  ngOnInit(): void {
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => this.loading = false, 1000);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => this.loading = false, 1000);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => this.loading = false, 1000);
    }
  }

}
