export class DettaglioScuola {
  istitutoId!: string;
  nomeIstituto!: string;
  codiceRiferimentoIstituto!: string;
  regione!: string;
  numeroClassiCaricate!: number;
  percRegistrazioniStudCompletate!: number;
  percQuestionariNonCognCompilati!: number;
  percQuestionariCognitiviCompilati!: number;
  percModuliAttivati!: number;
  percPreferenzeScuolaSuperiore!: number;
  percConsigliOrientativi!: number;
  liberatoriaId!: string;
}
