import { Authority } from "..";

export class User {


    id?: string;
    isAnonymous?: boolean;
    accountNonExpired?: boolean;
    accountNonLocked?: boolean;
    authorities?: Array<Authority>;
    credentialsNonExpired?: boolean;
    enabled?: boolean;
    issuer?: string;
    name?: string;
    password?: string;
    picture?: string;
    username?: string;
    isAdmin?: boolean;

    // firebase token
    token?: string;

    //Firebase Info
    uid?: string;
    email?: string;
    emailVerified?: boolean;
    photoURL?: string;
    displayName?: string;
}

export interface UserMetadata {
    createdAt: number;
    lastLoginAt: number;
    creationTime: Date;
    lastSignInTime: Date;
}

export interface StsTokenManager {
    expirationTime: number;
    accessToken: string;
    refreshToken: string;
}