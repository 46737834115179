<header id="second-nav">
  <button class="mobile-button" (click)="showMenu()">
    <i class="mobile-icon icon-menu"></i>
  </button>
  <div class="extra-form-00"></div>
  <div class="extra-form-01"></div>
  <div class="container-fluid">
    <div class="d-flex justify-content-end align-items-center flex-row">
      <div class="profile p-2">
        <div class="text">
          <div class="nome">{{authService.user && this.authService.user.email ? authService.user.email!.split('@')[0] : ''}}</div>
          <div class="ruolo">Admin</div>
        </div>
        <div class="avatar" (click)="op.toggle($event)">
          <!-- <img [src]="authService.user?.avatarUrl" [alt]="authService.user?.name" class="avatar-icon"> -->
          <img src="../../../assets/images/Inglese - cerchio.svg" alt="Admin 1" class="avatar-icon">

        </div>
      </div>
    </div>
  </div>
</header>

<p-overlayPanel styleClass="overlay-profile" [appendTo]="'body'" #op>
  <ng-template pTemplate>
    <nav id="settings-nav">
      <ul>
        <!-- <li>
          <a (click)="op.hide()" routerLink="/profilo" [routerLinkActive]="['router-link-active']">
            <span><i class="pi pi-cog"></i></span>{{"header.settings" | translate}}</a>
        </li> -->
        <li><a (click)="logout()"><span><i class="pi pi-sign-out"></i></span>{{"header.logout" | translate}}</a></li>
      </ul>
    </nav>
  </ng-template>
</p-overlayPanel>

<p-sidebar [(visible)]="showMobileMenu" [fullScreen]="true" [baseZIndex]="10000" [appendTo]="'body'">
  <div id="sidebarMobile">
    <div class="logo">
      <img src="assets/images/logo-esteso.svg" alt="Logo FUtuRI">
    </div>
    <ul class="menu">
      <li (click)="showMobileMenu=false" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/dashboard">
          <span class="futuri-icon"><i class="icon-home"></i></span>
          <span class="text">{{"header.dashboard" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
      <li (click)="showMobileMenu=false" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/classi">
          <span class="futuri-icon"><i class="icon-users-three"></i></span>
          <span class="text">{{"header.classi" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
      <li (click)="showMobileMenu=false" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/questionari">
          <span class="futuri-icon"><i class="icon-question-circle"></i></span>
          <span class="text">{{"header.questionari" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
    </ul>
    <div class="extra-00"></div>
    <div class="extra-01"></div>
  </div>
</p-sidebar>
