export class DettaglioClasse {
  nomeScuola!: string;
  gradoClasse!: number;
  sezioneClasse!: string;
  emailDocenteReferente!: string;
  onboardingDate!: Date;
  percRegistrazioniStudenteCompletate!: number;
  percQuestionariNonCognitiviCompilati!: number;
  percQuestionariCognAntologiaCompilati!: number;
  percQuestionariCognGrammaticaCompilati!: number;
  percQuestionariCognIngleseCompilati!: number;
  percQuestionariCognAlgebraAritmeticaCompilati!: number;
  percQuestionariCognGeometriaCompilati!: number;
  numeroModuliAttivati!: number;
  percPereferenzeScuolaSuperiore!: number;
  percConsigliOrietativi!: number;
}
