import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DashboardGenerale, TipologiaReport } from '../_model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private host = environment.host + environment.apiUrl;

  constructor(private http: HttpClient) { }

  getDashboardGenerale(): Observable<DashboardGenerale> {
    let url = `${this.host}/dashboard/generale`;
    return this.http.get<DashboardGenerale>(url);
  }

  getDashboardGeneraleReport(tipologia: TipologiaReport, regione?: string) {
    let url = `${this.host}/export/generale`;
    let queryParams: any = {
      tipologia: tipologia
    }
    if (regione) {
      queryParams.regione = regione;
    }
    return this.http.get(url, { params: queryParams, responseType: 'text' });
  }
}
