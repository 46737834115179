export class Constants {

  public static TITLE_INFO = 'INFO!';
  public static TITLE_ERROR = 'ERROR!';
  public static TITLE_WARNING = 'WARNING!';
  public static TITLE_SUCCESS = 'SUCCESS!';
  public static TITLE_OFFLINE = 'IS OFFLINE!';

  static Routing = class {
    public static LOGIN = { label: 'Login', path: 'login', routerLink: ['/login'] };
    public static DASHBOARD = { label: 'Dashboard', path: 'dashboard', routerLink: ['/dashboard'] };
    public static SCUOLE = { label: 'Scuole', path: 'scuole', routerLink: ['/scuole'] };
    public static CLASSI = { label: 'Classi', path: 'classi', routerLink: ['/classi'] };
    public static NOT_FOUND = { label: 'Not_found', path: 'not_found', routerLink: ['/not-found'] };
  }

  static Auth = class {
    public static USER_KEY = "user";
    public static ENV_KEY = "environment";
  }

  static Storager = class {
    public static USER_KEY = "user";
  }

  static Regioni = ["ABRUZZO","BASILICATA","CALABRIA",
  "CAMPANIA","EMILIA ROMAGNA","FRIULI-VENEZIA G.","LAZIO",
  "LIGURIA","LOMBARDIA","MARCHE","MOLISE","PIEMONTE","PUGLIA",
  "SARDEGNA","SICILIA","TOSCANA","TRENTINO-ALTO ADIGE",
  "UMBRIA","VALLE D' AOSTA","VENETO"
  ]


}
