import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {

    public spinnerObservable = new Subject<boolean>();
    public counter = 0;

    constructor() { }

    show() {
        this.counter += 1;
        if (this.counter == 1) {
            this.spinnerObservable.next(true);
        }
    }

    hide() {
        this.counter = this.counter > 0 ? this.counter - 1 : 0;
        if (this.counter == 0) {
            this.spinnerObservable.next(false);
        }
    }
}
