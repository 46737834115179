export class DashboardGenerale {
    numeroIstitutiTotale!: number;
    numeroStudentiTotale!: number;
    numeroClassiTotale!: number;
    numeroDocentiTotale!: number;
    numeroStudentiConEmailGenitoreTotale!: number;
    numeroQuestionariNonCognitiviCompilatiTotale!: number;
    numeroQuestionariCognitiviCompilatiTotale!: number;
    numeroDiariMetacognCompilatiTotale!: number;
    barChart!: Array<DashboardGrafici>;
}

export class DashboardGrafici {
    regione!: string;
    numeroIstituti!: number;
    numeroClassiPrime!: number;
    numeroClassiSeconde!: number;
    numeroClassiTerze!: number;
    numeroStudentiMaschi!: number;
    numeroStudentiFemmine!: number;
    numeroDocentiItaliano!: number;
    numeroDocentiStoria!: number;
    numeroDocentiGeografia!: number;
    numeroDocentiMatematica!: number;
    numeroDocentiScienze!: number;
    numeroDocentiInglese!: number;
    numeroDocentiTecnologia!: number;
    numeroDocentiArteImmagine!: number;
    numeroDocentiMusica!: number;
    numeroDocentiReligione!: number;
    numeroDocentiSostegno!: number;
    numeroDocentiPotenziamento!: number;
    numeroDocentiEducazioneFisica!: number;
    numeroDocentiSecondaLingua!: number;
    numeroDocentiRuoloSi!: number;
    numeroDocentiRuoloNo!: number;
    numeroStudentiConEmailGenitore!: number;
    numeroQuestionariNonCognitiviCompilati!: number;
    numeroQuestionariCognitiviCompilati!: number;
    numeroDiariMetacognCompilati!: number;
    numeroStudentiTotale!: number;
    numeroClassiTotale!: number;
    numeroDocentiTotale!: number;
}