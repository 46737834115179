<nav class="main-nav">
    <div class="logo" (click)="goToDashboard()">
      <img src="assets/images/logo.svg" alt="Logo FUtuRI">
    </div>
    <ul class="menu">
      <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/dashboard">
          <span class="futuri-icon"><i class="icon-home"></i></span>
          <span class="text">{{"header.dashboard" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
      <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/scuole">
          <span class="futuri-icon"><i class="icon-compass"></i></span>
          <span class="text">{{"header.scuole" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
      <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/classi">
          <span class="futuri-icon"><i class="icon-users-three"></i></span>
          <span class="text">{{"header.classi" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
    </ul>
    <div class="extra-00"></div>
    <div class="extra-01"></div>
  </nav>
