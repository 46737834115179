import { Plesso, Scuola, StatoClasse, Studente } from ".";

export class Classe {
    id!: string;
    grado!: number;
    sezione?: string;
    numeroStudenti?: number;
    yearPercentage?: number;
    annoScolastico!: number;
    referente?: boolean;
    codiceClasse?: string;
    propostaReferente?: boolean;
    propostaReferenteCognome?: string;
    propostaReferenteNome?: string;
    plesso?: Plesso;
    generazioneClasseId?: string;
    docenteReferenteId?: string;
    certCompClasseId?: string;
    scuola?: Scuola;
    stato?: StatoClasse;
    // per configurazione classi
    selected?: boolean;
    // per promozione
    studenti?: Array<Studente>
}