import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { Constants, LocalStoragerService, MyToastrService } from '../_utility';
import * as auth from 'firebase/auth';
import { environment } from 'src/environments/environment';
import { Type, User } from '../_model';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { SpinnerService } from '../_components/spinner/spinner.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userData: any;

  // helper methods refreshToken
  private userSubject: BehaviorSubject<any | null>;
  public userOb: Observable<any | null>;
  private refreshTokenTimeout?: any;

  constructor(
    public router: Router,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public lStorager: LocalStoragerService,
    private http: HttpClient,
    private toast: MyToastrService,
    private spinner: SpinnerService,
    private helperJwt: JwtHelperService
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.userOb = this.userSubject.asObservable();

    /* Saving user data in localstorage when logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;

        // Observable Timer refreshToken START
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
      }
    });

  }

  // Ritorna utente salvato nello storage
  get user(): User {
    const element = this.lStorager.getElement(Constants.Auth.USER_KEY);
    const user = element ? element : null;
    return user;
  }

  set user(user: User | null) {
    if (!user) {
      this.lStorager.removeElement(Constants.Auth.USER_KEY);
    } else {
      this.lStorager.setElement(Constants.Auth.USER_KEY, user);
    }
  }

  // Ritorna true quando utente è loggato e l'email è verifcato
  get isLoggedIn(): boolean {
    return !!this.user && !!this.token ? true : false;
  }

  // Ritorna il token o stringa vuota
  get token(): string {
    return this.user && this.user.token ? this.user.token : "";
  }

  // gestione refresh token Firebase
  isExpiredToken(): boolean {
    return this.helperJwt.isTokenExpired(this.token);
  }

  async refreshToken(): Promise<boolean> {
    try {
      let currentUser = await this.afAuth.currentUser;
      // console.log('currentUser', currentUser);
      let newToken = <string>await currentUser?.getIdToken(true);
      // console.log('newToken', currentUser);
      let refreshUser = this.user;
      refreshUser.token = newToken;
      this.user = refreshUser;

      // Observable Timer refreshToken START
      this.userSubject.next(currentUser);
      this.startRefreshTokenTimer();
      return true;
    } catch {
      return false;
    }
  }

  // signIn con customToken
  async SignIn(email: string, password: string) {
    let persistence: "session" | "local" | "none" = "none";
    await this.afAuth.setPersistence(persistence);
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(async (res: any) => {
        await this.SetUserInfo(res.user);
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            this.router.navigate(Constants.Routing.DASHBOARD.routerLink);
          }
        });
      })
      .catch((error) => {
        this.user = null;
        this.openToastrError("TOASTR.LOGIN", error.code);
        this.spinner.hide();
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      })
      .catch((error) => {
        this.toast.ERROR(Constants.TITLE_ERROR, error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.toast.INFO(Constants.TITLE_INFO, 'Password reset email sent, check your inbox.')
      })
      .catch((error) => {
        this.toast.ERROR(Constants.TITLE_ERROR, error);
      });
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      this.router.navigate(Constants.Routing.DASHBOARD.routerLink);
    });
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.SetUserData(result.user);
        this.router.navigate(Constants.Routing.DASHBOARD.routerLink);
      })
      .catch((error) => {
        this.toast.ERROR(Constants.TITLE_ERROR, error);
      });
  }

  /* Setting up user data when sign in with username/password, 
 sign up with username/password and sign in with social auth */
  async SetUserInfo(user: any) {
    this.spinner.show();
    try {
      const userData: User = {
        token: await user.getIdToken()
      } as User;
      this.user = userData;
      let profile: User = await firstValueFrom(this.profile());

      userData.accountNonExpired = profile.accountNonExpired;
      userData.accountNonLocked = profile.accountNonLocked;
      userData.credentialsNonExpired = profile.credentialsNonExpired;
      userData.username = profile.username;

      // set DATA
      userData.id = profile.id;
      userData.name = profile.name;
      userData.email = profile.email;
      userData.emailVerified = profile.emailVerified;
      userData.enabled = profile.enabled;
      userData.picture = profile.picture;
      userData.photoURL = profile.photoURL;
      userData.isAnonymous = profile.isAnonymous;
      userData.isAdmin = profile.authorities?.findIndex(authorities => authorities.authority === Type.admin) !== -1;
      this.user = userData;
    } finally {
      this.spinner.hide();
    }
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      this.user = null;
      // Observable Timer refreshToken STOP
      this.stopRefreshTokenTimer();
      this.userSubject.next(null);
      this.router.navigate(Constants.Routing.LOGIN.routerLink);
    });
  }

  profile(): Observable<Partial<User>> {
    let url = `${environment.host}${environment.apiUrl}/users`
    return this.http.get(url);
  }

  openToastrError(title: string, errorCode: string) {
    let msg;
    switch (errorCode) {
      case 'auth/wrong-password':
        msg = "TOASTR.WRONG_PWD";
        break;
      case 'auth/invalid-email':
        msg = "TOASTR.INVALID_EMAIL";
        break;
      case 'auth/user-disabled':
        msg = "TOASTR.USER_DISABLED";
        break;
      case 'auth/user-not-found':
        msg = "TOASTR.USER_NOT_FOUND";
        break;
      case 'auth/email-already-in-use':
        msg = "TOASTR.EMAIL_ALREADY_USE";
        break;
      case 'auth/weak-password':
        msg = "TOASTR.WEAK_PWD";
        break;
      default:
        msg = "TOASTR.GENERIC_ERROR";
        break;
    }
    this.toast.ERROR(title, msg);
  }

  // helper methods

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtBase64 = this.token!.split('.')[1];
    const jwtToken = JSON.parse(atob(jwtBase64));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    // console.log('startRefreshTokenTimer', expires, timeout);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

}
