<div id="main-nav">
  <app-main-nav></app-main-nav>
</div>
<div id="main-body">
  <app-header-admin></app-header-admin>
  <div id="main" class="bg-main">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
<div class="clear"></div>
